import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
    const [userInput, setUserInput] = useState('');
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [dots, setDots] = useState(0);

    useEffect(() => {
        let interval;
        if (loading) {
            interval = setInterval(() => {
                setDots(prevDots => (prevDots + 1) % 4);
            }, 1000);
        } else {
            setDots(0);
        }
        return () => clearInterval(interval);
    }, [loading]);

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setResponse('');
        fetch('/api/query', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query: userInput })
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false);
            if (data.message) {
                setResponse(data.message);
            } else {
                setResponse("Error: " + (data.error || "Unknown error"));
            }
        })
        .catch(error => {
            setLoading(false);
            console.error('Error:', error);
            setResponse("Error: " + (error.message || "Unknown error"));
        });
    };

    const handleReset = () => {
        setUserInput('');
        setResponse('');
        setLoading(false);
    };

    function formatResponse(response) {
        return response.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    }

    return (
        <div className="App">
            <h1>
                <img src="/logo.jpg" alt="Spark-E Logo" className="logo" /> 
                Spark-E
            </h1>
            <form onSubmit={handleSubmit}>
                <textarea 
                    value={userInput} 
                    onChange={handleInputChange} 
                    placeholder="Search the NEC"
                    rows="2"
                />
                <div className="buttons">
                    <button type="button" onClick={handleReset}>Reset</button>
                    <button type="submit">Search</button>
                </div>
            </form>
            <p>{loading ? `Loading${'.'.repeat(dots)}` : formatResponse(response)}</p>

            <div className="disclaimer-instructions">
                <h2>Instructions</h2>
                <p>
                    Spark-E is an artificial intelligence application designed to help electricians, in the field or office, navigate the “NFPA 70® 2017 National Electric Code” quickly and effectively. Use these tips and tricks to utilize Spark-E in the most effective way:
                </p>
                <ul>
                    <li>Spark-E is designed to help locate specific references in the code book for the user to look up based on their scenario. The system is capable of answering questions about the code, like “Where can I find information about pvc conduit?” or “ What size conductor do I need for a dryer with a 30A load?”. </li>
                    <li>Be as descriptive as possible in your question. Specify the applications and conditions such as location, temperature, voltage, amperage, etc. If Spark-E generates responses with missing information, it is advised to rewrite the question with more details.</li>
                    <li>Always reference your own code book. Spark-E may give incorrect information, it is the user’s responsibility to double-check any information given by Spark-E.</li>
                    <li>Please provide any suggestions and feedback to support@spark-e.ai.</li>
                </ul>
                <h2>Disclaimer</h2>
                <p>
                    Sparke-E is designed to help users locate where relevant information is in the “NFPA 70®  2017 Edition National Electrical Code”. While Spark-E strives to make the information on this website as accurate as possible, we make no claims, promises, or guarantees about the accuracy, completeness, or adequacy of the content on this site; we expressly disclaim any liability for errors and omissions of the content on this site. All generated information, referenced by the “NFPA 70®  2017 Edition National Electrical Code”, should be adequately reviewed by the user before application. Users are responsible for how they use the information generated by Spark-E, and should consult professionals as needed. Terms and conditions may be modified or updated without notice, continued use of Spark-E implies agreement to the modified terms.
                </p>
            </div>
        </div>
    );
}

export default App;
